<template>
    <el-dialog
            title="藏品清单"
            :visible.sync="dialogVisible"
            :fullscreen="true"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :before-close="handleClose">
        <el-button icon='el-icon-plus' v-show="ediongType == 0" size="small" type="primary" @click="addCollData()">添加藏品</el-button>
        <div class="minTitle">藏品清单</div>
        <div class="flex_b_c">
            <div>已选 {{ dataList.length }}件 藏品</div>
            <div>
                <el-button icon="el-icon-delete" v-show="ediongType == 0" size="small" type="danger" @click="beachDelete()">批量删除</el-button>
                <el-button v-show="auditStatus != 1 && ediongType != 0 && ediongType != 2" size="small" type="danger" @click="exportData()">
                    <i class="icon-piliangdaochu iconfont buIcon"/>
                    批量导出
                </el-button>
            </div>
        </div>
        <el-table
                :data="dataList"
                size="small"
                style="margin-left: 20px"
                height="calc(100vh - 260px)"
                class="table"
                ref="multipleTable"
                :row-key="'id'"
                :header-cell-style="{background:'#F9DFDF'}"
                @selection-change="selectionChangeHandle"
        >
            <el-table-column :reserve-selection="true" type="selection" width="50"/>
            <el-table-column label="序号" type="index" width="50"></el-table-column>
            <el-table-column prop="name" label="图标" width="120px">
                <template slot-scope="scope">
                    <div class="flex_a_c">
                        <div v-if="scope.row.carded == 0" class="icon-wenwukapian iconfont listIcon"></div>
                        <el-image
                                style="width: 36px; height: 36px"
                                :src="scope.row.picMasterViewUrl"
                                :fit="'cover'"
                                :preview-src-list="[scope.row.picMasterViewUrl]"
                        >
                            <div slot="error" class="image-slot">
                                <el-image
                                        style="width: 36px; height: 36px"
                                        :src="require('@/assets/img/default.png')"
                                        :fit="'cover'">
                                </el-image>
                            </div>
                        </el-image>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="collectionName" label="藏品名称"
                             show-overflow-tooltip></el-table-column>
            <el-table-column prop="helpNum" label="辅助账编号" show-overflow-tooltip></el-table-column>
            <el-table-column prop="generalNum" label="总账编号" show-overflow-tooltip></el-table-column>
            <el-table-column prop="oneTypeName" label="藏品分类" show-overflow-tooltip></el-table-column>
            <el-table-column prop="identified" label="是否鉴定" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ scope.row.identified == 0 ? '是' : '否' }}
                </template>
            </el-table-column>
            <el-table-column prop="leaveList" label="级别" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ getLeaveList(scope.row.collectionLevel) }}
                </template>
            </el-table-column>
            <el-table-column prop="integrityInfo" label="完残情况" show-overflow-tooltip></el-table-column>
            <el-table-column prop="integrity" label="完残程度" show-overflow-tooltip></el-table-column>
            <el-table-column prop="cStoreState" label="库存状态" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ $dictUtils.getDictLabel("cStore_state", scope.row.cStoreState, '-') }}
                </template>
            </el-table-column>
        </el-table>
        <div class="text_center" style="margin-top: 10px">
            <el-button v-if="auditStatus != 1 && ediongType != 0 && ediongType != 2" size="small" @click="handleClose()">关闭</el-button>
            <el-button v-else size="small" type="primary" @click="handleClose()">确定</el-button>
        </div>
        <collection-list ref="collList" @seltDataColl="getCollData"></collection-list>
    </el-dialog>
</template>

<script>
import CollectionList from "@/views/modules/collection/business/module/collectionList.vue";

export default {
    name: "collectionInventory",
    components: {CollectionList},
    data() {
        return {
            dialogVisible: false,
            dataList: [],
            dataListSelect: [],
            ediongType: 0,
            leaveList: [],
            selectData: [],
            auditStatus: 0,
        }
    },

    mounted() {
        this.$axios(this.api.collection.listLevelSelect).then(data => {
            if (data.status) {
                this.leaveList = data.data
            }
        })
    },

    methods: {
        getLeaveList(leave) {
            let data = this.leaveList.filter(item => {
                return item.level == leave
            })
            if (data.length) {
                return data[0].levelName
            }
        },

        //本展览选中的数据  num0新增、1详情  所有选中的藏品 auditStatus审核状态
        init(data, num, selectData, auditStatus) {
            this.dataList = data
            this.ediongType = num
            this.auditStatus = auditStatus
            if (selectData) {
                this.selectData = selectData
            }
            this.dialogVisible = true
        },

        //添加藏品
        addCollData() {
            this.$refs.collList.int(this.selectData)
        },

        getCollData(data) {
            this.dataList.push(...data)
            this.selectData.push(...data)
        },

        //批量删除
        beachDelete() {
            if (!this.dataListSelect.length) {
                this.$message.warning('请至少选择一条数据')
                return
            } else {
                this.$confirm('是否确认移除选中的藏品？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.dataListSelect.forEach(item2 => {
                        this.dataList.forEach((item1, index) => {
                            if (item1.id == item2.id) {
                                this.dataList.splice(index, 1)
                                this.selectData.splice(index, 1)
                            }
                        })
                    })
                    this.$refs.multipleTable.clearSelection()
                    this.$message.success('删除成功')
                })
            }
        },

        //导出
        exportData(row) {
            let ids = []
            if (this.dataListSelect.length == 0) {
                this.$message.warning('请至少选择一条数据')
                return
            }

            ids = this.dataListSelect.map(item => {
                if (item) {
                    return item.id
                }
            })
            this.exportExcel(this.api.collection.exhibitioninfoCollectionExport, ids, '藏品清单', 'post')
            this.$refs.multipleTable.clearSelection()
        },

        handleClose() {
            this.dialogVisible = false
        },

        //表格勾选数据
        selectionChangeHandle(val) {
            this.dataListSelect = val
        },
    },
}
</script>

<style scoped>
.minTitle {
    margin-top: 10px;
    font-weight: bold;
    font-size: 14px;
}
</style>
